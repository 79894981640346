.app-details-wrapper {
  width: 100%;
  max-width: 440px;
  margin-right: 64px;
}

.app-details-wrapper > * {
  margin-bottom: 32px;
}

.app-details-wrapper > *:last-child {
  margin-bottom: 0;
}

/* APP DETAILS */
.app-details {
  border: 1px solid #ccc;
  border-radius: 14px;
  padding: 16px;
}

.app-details > * {
  margin-bottom: 16px;
}

.app-details > *:last-child {
  margin-bottom: 0;
}

.app-details-header {
  display: flex;
  align-items: center;
  max-width: 100%;
}

.app-details .app-details-header .app-details-header-text {
  max-width: 100%;
  overflow: hidden;
}

.app-details .app-details-header .app-details-header-text > * {
  margin-bottom: 4px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}

.app-details .app-details-header .app-details-header-text > *:last-child {
  margin-bottom: 0;
}

.app-details .app-details-header .app-details-header-text h1 {
  color: #424242;
  font-weight: 600;
  font-size: 32px;
}

.app-details .app-details-header .app-details-header-text p {
  color: #9e9e9e;
  font-weight: 500;
  font-size: 18px;
}

.app-details .app-details-body p {
  color: #757575;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
}

.app-details .app-details-actions {
  display: flex;
  justify-content: flex-end;
}

/* QR CODE */
.qrcode-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
}

.qrcode-wrapper > * {
  margin-bottom: 16px;
}

.qrcode-wrapper > *:last-child {
  margin-bottom: 0;
}

.qrcode-body {
  flex: 1;
  text-align: center;
}

.qrcode-body > * {
  margin-bottom: 8px;
}

.qrcode-body > *:last-child {
  margin-bottom: 0;
}

.qrcode-body h2 {
  color: #424242;
  font-weight: 600;
  font-size: 20px;
}

.qrcode-body p,
.qrcode-body a {
  color: #757575;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
}

/* MULTI MENU STYLES */
.multi-menu-trigger.expanded:before,
.multi-menu-trigger.expanded:after {
  display: none;
}

.multi-menu-scroll-container {
  padding: 0;
}

.multi-menu-item {
  color: black;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  border-bottom: 1px solid #f5f5f5;
}

.multi-menu-item:last-child {
  border-bottom: 0;
}

.multi-menu-item:hover {
  color: black;
  background-color: #f5f5f5;
}

/* SendSMS input styles */
.sms-prepend-icon {
  margin: 0 0 0 8px;
}

.sms-button-append {
  height: 100%;
  padding: 0 16px;
}

@media screen and (max-width: 1100px) {
  .app-details-wrapper {
    width: 100%;
    max-width: 440px;
    margin-right: 32px;
  }
}

.details-clone-button-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  margin-right: 15px;
}

.details-clone-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  font: inherit;
  text-decoration: none;
  text-transform: uppercase;
  position: relative;
  outline: none;
  padding: 0 25px;
  border-radius: 50px;
  transition: all 0.2s;
  justify-content: center;
  border: 1px solid;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
  cursor: pointer;
}