.btn {
  position: relative;
  font: inherit;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  cursor: pointer;

  padding: 0 25px;
  height: 40px;
  border: none;
  border-radius: 50px;
}

.btn-text {
  font: inherit;
  font-size: inherit;
  font-weight: inherit;
  text-transform: inherit;
  text-decoration: underline;
  color: inherit;
  height: auto;
  padding: unset;
  border-radius: unset;
  background-color: transparent;
}

.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  background-color: transparent;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  padding: 0;

  transition: background-color 0.2s ease;
}

.btn-icon:hover,
.btn-icon:focus {
  background-color: rgba(0, 0, 0, 0.1);
}

.btn[disabled] {
  cursor: not-allowed;
}

.btn-loading .btn-loading-loader {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
