.icon {
  display: inline-block;
  height: 24px;
  width: 24px;
  mask-type: alpha;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;
  background: #333;
}

.icon-small {
  height: 20px;
  width: 20px;
}

.icon-placeholder {
  background: transparent;
  mask: none;
}

/* DEFINE ICONS */
.icon-send {
  mask-image: url('./icons/send.svg');
}

.icon-close {
  mask-image: url('./icons/close.svg');
}

.icon-exposure-plus-1 {
  mask-image: url('./icons/exposure-plus-1.svg');
}
