*::-webkit-scrollbar {
  display: none;
}

.status-bar-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}
