.color-stripe {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.color-stripe .color-stripe-bar {
  flex: 1;
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0.05);
}