.multi-menu {
  background: #fff;
}

.multi-menu-wrapper-attached > .multi-menu-inner-wrapper:before {
  background: #fff;
}

.multi-menu-item-label {
  padding-left: 16px;
}

.wrapped-select.white .multi-menu {
  background: #fff;
}

.wrapped-select.white .multi-select-menu-selection {
  font-size: 14px;
  font-weight: 500;
  background: #fff;
  padding-left: 16px;
}

.wrapped-select.white:not(.expanded) .multi-select-menu-selection {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  border-radius: 50px;
}

.wrapped-select.white.expanded .multi-select-menu-selection {
  box-shadow: none;
}

.multi-select-menu .icon {
  margin: -8px 4px -8px -2px;
  background-color: #9a9a9a;
}

.multi-select-menu .disabled .icon {
  opacity: 0.5;
}

.multi-select-menu .multi-menu-item.open .icon,
.multi-select-menu .multi-menu-item:hover .icon {
  background-color: #fff;
}
