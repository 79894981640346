.error-page-wrapper {
  padding: 20px;
}

.error-page-content h1 {
  margin-bottom: 0.4em;
}

@media (min-width: 450px) {
  .error-page-wrapper {
    background: #f1f1f1;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }

  .error-page-content {
    background: #fff;
    border-radius: 20px;
    padding: 40px;
    max-width: 400px;
  }
}
