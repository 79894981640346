@keyframes promptOpen {
  from {
    transform: translate(0, 200px);
  }

  to {
    transform: translate(0);
  }
}

@keyframes promptClose {
  to {
    transform: translate(0, 200px);
  }

  from {
    transform: translate(0);
  }
  
}

.install-prompt {
  position: absolute;
  bottom: 0;
  background: #fff;
  box-shadow: 0 0px 16px rgba(0, 0, 0, 0.2);
  color: #000;
  left: 0;
  right: 0;
  padding: 20px;
}

.install-prompt.opened {
  animation: promptOpen 0.3s 1;
  animation-fill-mode: forwards;
}

.install-prompt.closed {
  animation: promptClose 0.3s 1;
  animation-fill-mode: forwards;
}

.install-prompt-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}

.install-prompt-icon {
  margin-right: 10px;
  border-radius: 8px;
}

.install-prompt-button {
  display: inline-block;
  background: #06f;
  padding: 10px 20px;
  font-weight: 600;
  color: #fff;
  font-size: 14px;
  border-radius: 4px;
}

.install-prompt-close {
  position: absolute;
  top: -20px;
  right: -10px;
  padding: 30px;
  font-size: 30px;
  border-radius: 40px;
  color: #bbb;
  cursor: pointer;
}
