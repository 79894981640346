.input-wrapper > label {
  display: block;
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 8px 0;
}

.input-wrapper .input-group {
  position: relative;

  display: flex;
  align-items: flex-start;
  justify-content: stretch;

  background-color: white;
  box-shadow: inset 0 0 0 1px #ccc;
  border-radius: 6px;
  height: 44px;

  overflow: hidden;
}

.input-wrapper .input-group input {
  flex: 1;
  height: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  padding: 8px;

  font-size: 16px;
  font-weight: 500;
}

.input-wrapper .input-group .input-group-prepend,
.input-wrapper .input-group .input-group-append {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  height: 100%;
}

.input-wrapper .input-group .input-group-prepend > *,
.input-wrapper .input-group .input-group-append > * {
  border-radius: unset;
}
