.modal-dimmer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  height: 100vh;
  width: 100vw;
  background-color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
  z-index: 0;
}

.modal {
  z-index: 999;

  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}

.modal .modal-container {
  position: relative;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 5px -3px,
    rgba(0, 0, 0, 0.07) 0px 8px 10px 1px, rgba(0, 0, 0, 0.05) 0px 3px 14px 2px;
  border-radius: 12px;
  overflow: hidden;
  width: 100%;
  max-width: 580px;
  max-height: unset;
  padding: 48px;
  margin: auto;
  z-index: 1;
}

.modal .modal-container .modal-close {
  position: absolute;
  top: 16px;
  right: 16px;
}

.modal h1 {
  color: #424242;
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 32px;
}
